import React from 'react';
import {
  ChakraProvider,
  Box,
  Container,
  HStack,
  Image,
  Button,
  VStack,
  Text,
  CardBody,
  Card,
  Grid,
  GridItem,
  AspectRatio,
  Stack,
} from '@chakra-ui/react';

import logo from './assets/i/logo.png';
import bg from './assets/i/bg.jpg';
import delivery from './assets/i/delivery.png';
import features from './assets/data/features';
import theme from './theme';
import { FaTelegram } from 'react-icons/all';

const Orange = ({ children }) => <Text color={'#FCAF16'} display={'inline'}>{children}</Text>

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Container
        maxW="container.xl"
        p={0}
      >
        <Box py={3}>
          <HStack
            justifyContent={'space-between'}
            p={{
              base: 4,
              xl: 0,
            }}
          >
            <Image
              maxH={{
                base: '40px',
                md: '50px',
              }}
              src={logo}
            />
            <Button
              display={{
                base: 'none',
                sm: 'inline-flex',
              }}
              onClick={() => window.open('https://t.me/aribapizzakzn_bot', '_blank')}
              py={4}
              size={'lg'}
            >
              <HStack>
                <FaTelegram size={32} />
                <Text textTransform={'uppercase'}>Заказать</Text>
              </HStack>
            </Button>
          </HStack>
        </Box>
        <VStack
          backgroundImage={`url(${bg})`}
          backgroundSize={'cover'}
          backgroundPosition={'center center'}
          backgroundRepeat={'no-repeat'}
          p={4}
          minH={'600px'}
          justifyContent={'end'}
        >
        </VStack>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            lg: 'repeat(2, 1fr)',
          }}
          alignItems={'stretch'}
          gap={{
            base: 6,
            lg: 2,
          }}
          pt={2}
          px={4}
          >
          <GridItem>
            <Text
              fontWeight={'bold'}
              fontSize={'3xl'}
            >
              <Orange>
                Вы можете заказать пиццу домой или в офис. Это просто!
              </Orange>
            </Text>
          </GridItem>
          <GridItem>
            <Button
              bgColor={'#FCAF16'}
              _hover={{
                bgColor: '#d97b01',
              }}
              _active={{
                bgColor: '#d97b01',
              }}
              w={'100%'}
              h={{
                base: '80px',
                lg: '100%',
              }}
              fontSize={{
                base: 'xl',
                sm: '2xl',
                md: '3xl',
              }}
              color={'black'}
              textTransform={'uppercase'}
              onClick={() => window.open('https://t.me/aribapizzakzn_bot', '_blank')}
            >
              <HStack
                spacing={4}
              >
                <Image w={'100px'} src={delivery} />
                <Box>Сделать заказ</Box>
              </HStack>
            </Button>
          </GridItem>
        </Grid>
        <Box
          h={6}
        />
        <VStack
          spacing={6}
          p={{
            base: 4,
            xl: 0,
          }}
        >
          <Card>
            <CardBody>
              <VStack
                alignItems={'start'}
              >
                <Text>
                  <Orange>АРИБА ПИЦЦА</Orange> ― это про неспешные семейные завтраки и ужины, деловые встречи и дружеские посиделки. Мы заботимся
                  о вкусе и качестве наших блюд, о здоровье гостей, и поэтому замороженному предпочитаем свежее, а магазинному ―
                  домашнее. Например, даже соусы мы готовим сами ― они натуральные и подходят и детям.
                </Text>
                <Text>
                  <Orange>Если Вы ― поклонник настоящей пиццы,</Orange> то поздравляем ― Вы обратились по нужному адресу! У Вас есть уникальная
                  возможность получить на дом пиццу от лучших мастеров своего дела!
                </Text>
              </VStack>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <VStack
                spacing={4}
              >
                <Grid
                  templateColumns={{
                    base: 'repeat(1, 1fr)',
                    lg: 'repeat(2, 1fr)',
                  }}
                  w={'100%'}
                  gap={2}
                >
                  {features.map((feature) => (
                    <GridItem
                      key={feature.title}
                    >
                      <Card
                        h={'full'}
                        variant={'elevated'}
                        boxShadow={'none'}
                      >
                        <CardBody
                          h={'full'}
                        >
                          <Stack
                            spacing={4}
                            h={'full'}
                            direction={{
                              base: 'column',
                              sm: 'row'
                            }}
                            alignItems={{
                              base: 'stretch',
                              sm: 'center'
                            }}
                          >
                            <AspectRatio
                              ratio={1}
                              minW={'100px'}
                            >
                              <Image
                                src={feature.icon}
                                rounded={'lg'}
                              />
                            </AspectRatio>
                            <VStack
                              alignSelf={'stretch'}
                              alignItems={'start'}
                              h={'100%'}
                            >
                              <Text
                                fontWeight={'bold'}
                                fontSize={'lg'}
                                textTransform={'capitalize'}
                              >
                                <Orange>{feature.title}</Orange>
                              </Text>
                              <Text>{feature.description}</Text>
                            </VStack>
                          </Stack>
                        </CardBody>
                      </Card>
                    </GridItem>
                  ))}
                </Grid>
              </VStack>
            </CardBody>
          </Card>
          <VStack
            pb={4}
          >
            <Text
              fontWeight={'bold'}
              fontSize={'lg'}
              textAlign={'center'}
            ><Orange>НЕ ТЕРПИТСЯ ПОПРОБОВАТЬ?</Orange></Text>
            <Text
              fontWeight={'bold'}
              fontSize={'lg'}
              textAlign={'center'}
            ><Orange>ПЕРЕХОДИТЕ В НАШ TELEGRAM-БОТ И ЗАКАЖИТЕ ДОСТАВКУ ПРЯМО СЕЙЧАС!</Orange></Text>
            <Button
              size={'lg'}
              colorScheme={'blue'}
              onClick={() => window.open('https://t.me/aribapizzakzn_bot', '_blank')}
            >
              <HStack>
                <FaTelegram />
                <Text>Telegram</Text>
              </HStack>
            </Button>
          </VStack>
        </VStack>
      </Container>
    </ChakraProvider>
  );
}

export default App;
