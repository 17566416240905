import easy from '../i/icons/illustration1.jpg'
import customizable from '../i/icons/illustration2.jpg'
import fees from '../i/icons/illustration3.jpg'
import support from '../i/icons/illustration4.jpg'
import secure from '../i/icons/illustration5.jpg'
import marketing from '../i/icons/illustration6.jpg'

const features = [
  {
    title: 'ГЛАВНЫЙ КОМПОНЕНТ В ПИЦЦЕ — ТЕСТО',
    description: 'Сделать его «живым» и невероятно вкусным,\n' +
      'с сохранением традиционных итальянских\n' +
      'рецептов — это непросто. Но у нас получается!',
    icon: easy,
  },
  {
    title: 'МЫ НЕ ИСПОЛЬЗУЕМ ФРИТЮРНОЕ МАСЛО',
    description: '(о его вреде для здоровья известно всем),\n' +
      'поэтому все блюда запекаются в печи.',
    icon: customizable,
  },
  {
    title: 'ПОЛНОСТЬЮ ОТКРЫТАЯ КУХНЯ',
    description: 'Нет потайных уголков, и поэтому все процессы\n' +
      'приготовления — всегда перед глазами!',
    icon: fees,
  },
  {
    title: 'БЫСТРАЯ ДОСТАВКА ПО КАЗАНИ',
    description: 'При сохранении органолептических свойств\n' +
      'блюд (аппетитный вид, консистенция, цвет,\n' +
      'запах, вкус)',
    icon: support,
  },
  {
    title: 'СКИДКА 15% НА ВАШ ДЕНЬ РОЖДЕНИЯ',
    description: 'А также за 3 дня до и 3 дня после даты.',
    icon: secure,
  },
  {
    title: 'МЫ ПРОВОДИМ ДЕТСКИЕ ПРАЗДНИКИ!',
    description: 'К услугам наших самых маленьких гостей —\n' +
      'увлекательные мастер-классы\n' +
      'по приготовлению собственной пиццы.',
    icon: marketing,
  }
]

export default features